import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../";
import { getProfileById, getProfileConfigList, patchProfile, postProfile, postProfileConfigList } from "../thunks";
import { IJackpotProfile, JackpotPullType } from "../../../entities/jackpotGame";

interface JackpotProfileState {
  loading: boolean;
  saveLoading: boolean;
  global?: IJackpotProfile
  configuration: JackpotPullType[],
}

const addLoadingCases = (
  builder: ActionReducerMapBuilder<JackpotProfileState>,
  action: any
) => {
  builder.addCase(action.pending, (state: JackpotProfileState) => {
    state.saveLoading = true;
  });

  builder.addCase(action.fulfilled, (state: JackpotProfileState) => {
    state.saveLoading = false;
  });
};

const initialState: JackpotProfileState = {
  loading: false,
  saveLoading: false,
  configuration: [],
}

export const jackpotProfileSlice = createSlice({
  name: "jackpotProfile",
  initialState,
  reducers: {
    clearJackpotProfile: (state) => {
      state.global = undefined;
      state.configuration = [];
    }
  },
  extraReducers: (builder) => {
    // getProfileConfigList
    builder.addCase(getProfileConfigList.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(getProfileConfigList.fulfilled, (state, action) => {
      if (action.payload) {
        const result = action.payload.map((item, index) => ({
          index: index + 1,
          prizeId: item.gameEntity.id,
          langs: [],
        }));
        if (result.length > 0) {
          state.configuration = result;
        } else {
          state.configuration = [{ index: 1, prizeId: 0, langs: [] }]
        }
      } else {
        state.configuration = [{ index: 1, prizeId: 0, langs: [] }]
      }
      state.loading = false;
    })
    // getProfileById
    builder.addCase(getProfileById.pending, state => {
      state.loading = true;
    })
    builder.addCase(getProfileById.fulfilled, (state, action) => {
      if (action.payload) {
        state.global = action.payload;
      } else {
        state.global = undefined;
      }
      state.loading = false;
    })

    const saveActions = [
      postProfile,
      patchProfile,
      postProfileConfigList,
    ]
    saveActions.forEach((action) => addLoadingCases(builder, action));

  },
});

export const { clearJackpotProfile } = jackpotProfileSlice.actions;

export const selectProfileJackpot = (state: RootState) => state.jackpot.profile;

export default jackpotProfileSlice.reducer;
