import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { IJackpotPrizeList, IJackpotProfile, JackpotProfileList } from "../../entities/jackpotGame";
import { getPrizeJackpot, getProfiles } from "./thunks";

interface JackpotState {
  profiles: JackpotProfileList[],
  entities: IJackpotPrizeList[]
  loading: boolean;
  profile?: {
    global: IJackpotProfile
  }
}

const initialState: JackpotState = {
  loading: false,
  entities: [],
  profiles: [],
}

export const jackpotSlice = createSlice({
  name: "jackpotGame",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getPrizeJackpot.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(getPrizeJackpot.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.entities = action.payload.sort((a, b) => a.id - b.id);
      }
      state.loading = false;
    })

    builder.addCase(getProfiles.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(getProfiles.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.profiles = action.payload.sort((a, b) => a.id - b.id);
      }
      state.loading = false;
    })
  },
});

// export const { } = jackpotSlice.actions;

export const selectJackpotList = (state: RootState) => state.jackpot.tables;

export default jackpotSlice.reducer;
