import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../";
import {
  ElementOrder,
  HowToWinGlobal,
  IJackpotParticipateForm,
  JackpotDatabaseContent,
  JackpotDatabaseGlobal,
  JackpotParticipateTranslation,
  NotificationGlobal,
  PopupSettingsForm,
  ResponseHowToWinlangs,
  ResponseNotificationLangs,
  ResponsePopupLangs,
  ResponsePostContentLang,
} from "../../../entities/jackpotGame";
import {
  gatHowToWin,
  getContentJacpotById,
  getElementOrder,
  getMostById,
  getNotification,
  getParcitipateById,
  getPopup,
  getPrizeJackpotById,
  patchContentById,
  patchContentTranslationById,
  patchElementOrder,
  patchGlobalNotification,
  patchHowToWinGlobal,
  patchHowToWinTranslation,
  patchMostById,
  patchMostChanceTranslate,
  patchNotificationTranslation,
  patchParcitipateById,
  patchParcitipateTranslate,
  patchPopupSettings,
  patchPopupTranslationById,
  patchPrizeJackpotById,
  postContentById,
  postContentTranslationById,
  postElementOrder,
  postGlobalNotification,
  postHowToWinGlobal,
  postHowToWinTranslation,
  postMostById,
  postMostChanceTranslate,
  postNotificationTranslation,
  postParcitipateById,
  postParcitipateTranslate,
  postPopupSettings,
  postPopupTranslationById,
  postPrizeJackpotById,
} from "../thunks";
import {
  parseGetGlobalSetting,
  parseParticipatePostToForm,
} from "../../../middleware/jackpot/parseRequestMiddlewares";
import { parseNotifGlobal } from "../../../middleware/jackpot/parseNotificationTranslation";



interface JackpotState {
  loading: boolean;
  saveLoading: boolean;
  main?: JackpotDatabaseGlobal;
  contentLocalization: {
    global?: JackpotDatabaseContent;
    langs?: ResponsePostContentLang[];
  };
  popupSettings: {
    global?: PopupSettingsForm;
    langs?: ResponsePopupLangs[];
  };
  howToWin: {
    global?: HowToWinGlobal;
    langs?: ResponseHowToWinlangs[];
  };
  endNotification: {
    global?: NotificationGlobal;
    langs?: ResponseNotificationLangs[];
  };
  parcitipate: {
    global?: IJackpotParticipateForm;
    langs?: JackpotParticipateTranslation[];
  };
  mostChance: {
    global?: IJackpotParticipateForm;
    langs?: JackpotParticipateTranslation[];
  };
  pageElementOrder?: ElementOrder[];
}

const initialState: JackpotState = {
  loading: false,
  saveLoading: false,
  contentLocalization: {},
  popupSettings: {},
  howToWin: {},
  endNotification: {},
  parcitipate: {},
  mostChance: {},
};

const addLoadingCases = (
  builder: ActionReducerMapBuilder<JackpotState>,
  action: any
) => {
  builder.addCase(action.pending, (state: JackpotState) => {
    state.saveLoading = true;
  });

  builder.addCase(action.fulfilled, (state: JackpotState) => {
    state.saveLoading = false;
  });
};

export const jackpotSlice = createSlice({
  name: "jackpot",
  initialState,
  reducers: {
    clearJackpot: (state) => {
      state.main = undefined;
      state.contentLocalization.global = undefined;
      state.popupSettings.global = undefined;
      state.howToWin.global = undefined;
      state.endNotification.global = undefined;
      state.parcitipate.global = undefined;
      state.mostChance.global = undefined;

      state.contentLocalization.langs = undefined;
      state.popupSettings.langs = undefined;
      state.howToWin.langs = undefined;
      state.endNotification.langs = undefined;
      state.parcitipate.langs = undefined;
      state.mostChance.langs = undefined;

      state.pageElementOrder = undefined;
    },
  },
  extraReducers: (builder) => {
    // getPrizeJackpotById
    builder.addCase(getPrizeJackpotById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPrizeJackpotById.fulfilled, (state, action) => {
      if (action.payload) {
        state.main = parseGetGlobalSetting(action.payload);
      }
      state.loading = false;
    });
    // getContentJacpotById
    builder.addCase(getContentJacpotById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getContentJacpotById.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.contentLocalization.global = action.payload.globalSettings;
        state.contentLocalization.langs = action.payload.langs;
      } else {
        state.contentLocalization.global = undefined;
        state.contentLocalization.langs = undefined;
      }
    });
    // getPopup
    builder.addCase(getPopup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPopup.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        const { globalSettings, langs } = action.payload;
        if (globalSettings && Object.keys(globalSettings).length > 0) {
          state.popupSettings.global = globalSettings;
        } else {
          state.popupSettings.global = undefined;
        }
        if (langs.length > 0) {
          state.popupSettings.langs = langs;
        } else {
          state.popupSettings.langs = undefined;
        }
      }
    });
    // gatHowToWin
    builder.addCase(gatHowToWin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(gatHowToWin.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        const { globalSettings, langs } = action.payload;
        if (globalSettings && Object.keys(globalSettings).length > 0) {
          state.howToWin.global = globalSettings;
        } else {
          state.howToWin.global = undefined;
        }
        if (langs.length > 0) {
          state.howToWin.langs = langs;
        } else {
          state.howToWin.langs = undefined;
        }
      }
    });
    // getNotification
    builder.addCase(getNotification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNotification.fulfilled, (state, action) => {
      if (action.payload) {
        const { globalSettings, langs } = action.payload;
        if (globalSettings && Object.keys(globalSettings).length > 0) {
          state.endNotification.global = parseNotifGlobal(globalSettings);
        } else {
          state.endNotification.global = undefined;
        }
        if (langs && langs.length > 0) {
          state.endNotification.langs = langs;
        } else {
          state.endNotification.langs = undefined;
        }
      }
      state.loading = false;
    });
    // getElementOrder
    builder.addCase(getElementOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getElementOrder.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.pageElementOrder = action.payload;
      } else {
        state.pageElementOrder = [
          { position: 0, title: "jackpot_description_first_block" },
          { position: 1, title: "jackpot_description_second_block" },
          { position: 2, title: "jackpot_description_third_block" },
          // { position: 3, title: "jackpot_leaderboard" },
          { position: 3, title: "jackpot_most_chances" },
          { position: 4, title: "jackpot_parcitipate" },
        ];
      }
    });
    // getParcitipateById
    builder.addCase(getParcitipateById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getParcitipateById.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        const { config, langs } = action.payload;
        if (config && Object.keys(config).length > 0) {
          state.parcitipate.global = parseParticipatePostToForm(
            config,
            "parcitipate"
          );
        } else {
          state.parcitipate.global = undefined;
        }
        if (langs.length > 0) {
          state.parcitipate.langs = langs;
        } else {
          state.parcitipate.langs = undefined;
        }
      }
    });
    builder.addCase(getParcitipateById.rejected, (state) => {
      state.parcitipate.global = undefined;
      state.parcitipate.langs = undefined;
    });
    // getMostById
    builder.addCase(getMostById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMostById.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        const { config, langs } = action.payload;
        if (config && Object.keys(config).length > 0) {
          state.mostChance.global = parseParticipatePostToForm(config, "most");
        } else {
          state.mostChance.global = undefined;
        }
        if (langs.length > 0) {
          state.mostChance.langs = langs;
        } else {
          state.mostChance.langs = undefined;
        }
      }
    });
    builder.addCase(getMostById.rejected, (state) => {
      state.mostChance.global = undefined;
      state.mostChance.langs = undefined;
    });

    const saveActions = [
      postPrizeJackpotById,
      patchPrizeJackpotById,
      postContentById,
      patchContentById,
      postContentTranslationById,
      patchContentTranslationById,
      postPopupSettings,
      patchPopupSettings,
      postPopupTranslationById,
      patchPopupTranslationById,
      postHowToWinGlobal,
      patchHowToWinGlobal,
      postHowToWinTranslation,
      patchHowToWinTranslation,
      postGlobalNotification,
      patchGlobalNotification,
      postNotificationTranslation,
      patchNotificationTranslation,
      postElementOrder,
      patchElementOrder,
      postParcitipateById,
      patchParcitipateById,
      postParcitipateTranslate,
      patchParcitipateTranslate,
      postMostById,
      patchMostById,
      postMostChanceTranslate,
      patchMostChanceTranslate,
    ];
    saveActions.forEach((action) => addLoadingCases(builder, action));
  },
});

export const { clearJackpot } = jackpotSlice.actions;

export const selectJackpot = (state: RootState) => state.jackpot.jackpot;

export default jackpotSlice.reducer;
