export const parsePricingData = (data: any) => {
  return ({
    progressBarSettings: {
      enabled: data.progressBarSettings.progressBarSettingsActive,
      progressBarType: data.progressBarSettings.progressBarType,
      progressValue: data.progressBarSettings.porgressValue,
    },
    caseType: {
      caseType: data.caseSettings.caseType,
      primaryCurrency: data.caseSettings.primaryCurrency,
      caseAmount: data.caseSettings.caseAmount,
      limitPlatformCases: {
        enabled: data.caseSettings.limitPlatformCases > 0,
        amount: data.caseSettings.limitPlatformCases
      },
      limitUserCases: {
        enabled: data.caseSettings.limitUserCases > 0,
        amount: data.caseSettings.limitUserCases
      },
      discountCaseAmount: {
        enabled: data.caseSettings.discountCaseAmount > 0,
        amount: data.caseSettings.discountCaseAmount
      },
      timeUntilNextCases: {
        timeUntilNextCase: data.caseSettings.TimeUntilNextCase,
        enabled: data.caseSettings.TimeUntilNextCase.length > 0
      },
    }
  })
}

export const parseResponsePricingCase = (data: any): {
  primaryCurrency: string,
  caseAmount: number,
  discountCaseAmount: number,
  TimeUntilNextCase: string,
  limitPlatformCases: number,
  limitUserCases: number,
} => {
  return ({
    primaryCurrency: data.primaryCurrency,
    caseAmount: data.caseAmount,
    discountCaseAmount: data.discountCaseAmount.amount,
    TimeUntilNextCase: data.timeUntilNextCases.timeUntilNextCase,
    limitPlatformCases: data.limitPlatformCases.amount,
    limitUserCases: data.limitUserCases.amount,
  }
  )
}