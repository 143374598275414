import { createAsyncThunk, GetThunkAPI } from "@reduxjs/toolkit";
import { apiUrlV1 } from "../../config/config";
import { agent, encodeGetParams } from "../../api";
import { CaseBonusList, CaseGlobalBonus, ReqBonusLocal } from "../../entities/cases";
import { AsyncThunkConfig } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { addNotification } from "../Notification/notificateStore";

const DEFAULT_URL = `${apiUrlV1}/organization/game/case`;

const SuccessfulNotification = (thunkApi: GetThunkAPI<AsyncThunkConfig>) => {
  thunkApi.dispatch(
    addNotification({ status: "success", text: "Successfully saved" })
  );
}

export const getBonusesCase = createAsyncThunk(
  "case/bonus-list",
  async (): Promise<CaseBonusList[]> => {
    const query = encodeGetParams({ page: 1, limit: 250 })
    const req = await agent.get(`${DEFAULT_URL}/bonus/list${query}`);
    if (req.ok) {
      const res = await req.json() as { list: CaseBonusList[] }
      return res.list;
    }
    return [];
  }
);

export const postBonusCase = createAsyncThunk(
  "case/post-bonus",
  async (data: CaseGlobalBonus, thunkApi) => {
    const req = await agent.post(`${DEFAULT_URL}/bonus`, {
      body: JSON.stringify(data)
    });
    if (req.ok) {
      const res = await req.json() as { id: number };
      thunkApi.dispatch(getCaseBonusById(res.id))
      SuccessfulNotification(thunkApi);
      return res;
    }
  }
);

export const patchBonusCase = createAsyncThunk(
  "case/patch-bonus",
  async (data: CaseGlobalBonus, thunkApi) => {
    const req = await agent.patch(`${DEFAULT_URL}/bonus`, {
      body: JSON.stringify(data)
    });
    if (req.ok) {
      const res = await req.json() as { id: number };
      thunkApi.dispatch(getCaseBonusById(res.id));
    }
  }
);

export const deleteBonusCase = createAsyncThunk(
  `case/delete-bonus`,
  async (id: number, thunkApi) => {
    const req = await agent.delete(`${DEFAULT_URL}/bonus/${id}`);
    if (req.ok) {
      thunkApi.dispatch(getBonusesCase());
    }
  }
)

export const getCaseBonusById = createAsyncThunk(
  "case/get-bonus-by-id",
  async (id: number): Promise<CaseGlobalBonus | null> => {
    const req = await agent.get(`${DEFAULT_URL}/bonus/${id}`);
    if (req.ok) {
      const res = await req.json() as { caseEntity: CaseGlobalBonus };
      return res.caseEntity;
    }
    return null;
  }
);

export const getCaseBonusLocalization = createAsyncThunk(
  `case/get-bonus-localizations-by-id`,
  async (id: number): Promise<ReqBonusLocal[] | []> => {
    const req = await agent.get(`${DEFAULT_URL}/bonus/${id}/content-localization`);
    if (req.ok) {
      const res = await req.json();
      return res.entities as ReqBonusLocal[];
    }
    return [];
  }
)

export const postCaseBonusLocalization = createAsyncThunk(
  `case/post-bonus-localizations`,
  async (props: { data: ReqBonusLocal, id: number }) => {
    const { data, id } = props;
    await agent.post(`${DEFAULT_URL}/bonus/${id}/content-localization`, {
      body: JSON.stringify(data),
    });
  }
)

export const patchCaseBonusLocalization = createAsyncThunk(
  `case/patch-bonus-localizations`,
  async (props: { data: ReqBonusLocal, id: number }) => {
    const { data, id } = props;
    await agent.patch(`${DEFAULT_URL}/bonus/${id}/content-localization`, {
      body: JSON.stringify(data),
    });
  }
)
